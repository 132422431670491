

th.course_banner {
  vertical-align: top;
  padding-top: 1rem !important;
}

.course_banner .ant-row{
  margin-bottom: 1rem;
}

.course_banner label{
  margin-right:1rem;

}
.course_banner__body {
  width: 100%;
}