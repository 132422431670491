.comments .tag{
  padding-bottom: 4px;
  /* display: inline; */
}
.comments .sub-table .ant-table{
  margin-left: 0px !important;
}

.comments .bodytext-column{
  width: 100%;
}

.comments .fr{
  float:right;
}

.comments .sub-table{
  border-left: 2px dashed #d0d0d0;
}

.comments-comment-as-label{
  padding: 5px;
}

.comments-comment-as-select{
  margin-left: 5px;
  padding: 5px;
}

.comments tr.ant-table-expanded-row.ant-table-expanded-row-level-1 td.ant-table-cell:empty{
  display: none;
}


.comments .ant-table-row-expand-icon-spaced{
  display: none;
}

.comments td.ant-table-column-sort {
  background-color: transparent;
}

.comments__moderator-section {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
.comments__moderator {
  width: fit-content;
  margin-bottom: 0.25rem;
}
.comments__add-moderator-button {
  width: 180px;
}

.comments__faculty-search-modal-header,
.comments__faculty-search-modal-rendered-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.comments__faculty-search-modal-header {
  font-weight: bold;
}

.comments__faculty-search-modal--checkbox {
  width: 8%;
}

.comments-child-comment-bodyText{
  background-color: #F0F0F0;
  font-family: Helvetica, Arial, sans-serif;
  padding: .5rem;
  border-radius: 2px;
}

.comments-user{
  font-size: 110%;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.comments-user-detail{
  display: flex;
  flex-direction: column;
}

.comments-faculty-text-flair{
  color: #0166DC;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif
}

.comments-avatar{
  border-radius: 50%;
  border: 1px solid #999;
  background: white;
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 12px;
}

.comments-faculty-badge{
  padding-left: .25rem;
  padding-right: .25rem;
  padding-bottom: .25rem;
}
